.knowledgebase-title{
    color: #32393f ;
    font-size: medium;
}

.folder-name-back{
    display: flex;
	justify-content: space-between;
}

.folder-name-back i{
    font-size: xx-large;
    cursor: pointer;
}

.folder-name-back h4{
    padding-top: 10px;
    padding-left: 10px;
}

.knowledge-create-button{
    background-color: white !important;
    color: #32393f !important;
    border: 1px solid #8c8c8c !important;
    margin-right: 5px;
}

.download-content-editor{
	font-size: xx-large;
    cursor: pointer;
	color: #1976d2;
	padding-right: 10px;
}

.ql-container{
	height: 60vh !important;
}
.folder-pic img{
    width: 20px;
    margin-right: 10px;
    vertical-align: text-top;
}
.folder-pic i{
    margin-right: 5px;
}
.accordian-folder{
    margin-top: 1rem;
}
/* ----------------- Question --ui ----------------------  */

.Question-knowledge{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 32px;
}
.Question-knowledge i, .knowledge-editable-question .first-editable i, .second-input-child i{
    color: #1976d2;
    cursor: pointer;
}
.knowledge-editable-question .first-editable input{
    width: 100%;
    background-color: #f5f6f8 !important;
    border: 1px solid #f5f6f8 !important;
    padding: 0.8rem;
    border-radius: 5px;
    font-size: 15px;
    margin-left: 3px;
}
.knowledge-editable-question .first-editable{
    display: flex;
    align-items: center;
}
.first-input-div :nth-child(1){
   margin-top: 0.5rem;
}
.second-input-child {
    padding-left: 3rem;
}
.second-input-child input{
    width: 70%;
    background-color: #f5f6f8 !important;
    border: 1px solid #f5f6f8 !important;
    padding: 0.4rem;
    border-radius: 5px;
    font-size: 13px;
    margin-bottom: 10px;
}
.editable-textarea{
    display: flex;
    justify-content: space-around;
}
.knowledge-textarea textarea{
  border: 1px solid #ced4da;
  width: 300px;
  overflow: hidden;
  resize: vertical;
  height: 100px;
  background-color: #f5f6f8;
}
.document-input-filed{
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
}
.document-folder-upload{
    display: flex;
    margin-top: 1rem;
    /* align-items: center; */
    gap: 40px;
}
.document-folder-upload .uploading-document-folder{
    border: 1px dashed;
    padding: 8rem;
}
.description-document{
    margin-top: 1rem;
}

.question-bank-margin{
    margin-top: 1rem;
}

.add-option-span{
    font-size: 12px;
    cursor: pointer;
    margin-left: 48px;
    color: #0077b6;
}

.text-area{
    margin-top: 20px;
    width: 50%;
    margin-right: 20px;
}

.delQBank .modal-footer{
    justify-content: center;
    border-top: none;
}

.delQBank .btn.btn-secondary.delete-btn{
    background: rgb(255, 255, 255);
    color: #0077b6;
    border: rgb(255, 255, 255);
    box-shadow: 0 2px 6px #00000029;
}

.delQBank .btn.btn-secondary.cancel-btn{
    background: #0077b6;
    color: rgb(255, 255, 255);
    border: #0077b6;
    width: 40%!important;
}

.delQBank .modal-header{
    background-color: #0077b6;
}

.create-qBank-modal .modal-header{
    padding: 1rem 1rem 0.2rem;
}

.create-qBank-modal button{
    margin-top: 7px;
    margin-bottom: -7px;
}

.caption-style{
    margin-left: 2.9rem;
    font-size: 12px;
    padding: 2px;
    display: block;
}

.selected-values-container {
    max-width: 83%; /* Control the width to prevent overflow */
}

/* --------------------------------new-resource-ui---------------------------------------- */
.resource-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
    text-align: center;        
}

.resource-child{
    position: relative;
}
.resource-grid .resource-child div{
    /* padding: 30px; */
    transition: 0.1s ease;
}
.resource-grid .resource-child div:hover{
    /* -webkit-transform: scale(1.1); */
    /* transform: scale(1.1); */
    background: #fff;
    border-radius: 8px;
    cursor: pointer;
}
.resource-grid .resource-child div h3{
    font-size: 1.45em;
}

.resource-child-edit-div{
    display: none;
    position: absolute;
    top: -37px;
    right: 0;
}

/* .resource-child:hover .resource-child-edit-div{
    display: block;
} */
/* ---------------------header-banner------------------------- */
.header-banner{
    background: linear-gradient(50deg, #0c4996 31%, #191b6b 70%);
    color: #fff;
    padding: 48px 0 0;
    text-align: left;
}
.custom-breadcrumb {
    padding-bottom: 10px;  
}

.custom-breadcrumb .breadcrumb-item a {
    color: white; 
    text-decoration: none; 
}

.custom-breadcrumb .breadcrumb-item a:hover {
    color: #f0f0f0; 
}

.text-layout{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: nowrap;
}
.column-left .category-name{
    color: #fff;
}
.navigation-list{
    flex: 0 1 calc(100% - 70px);
    transition: height .3s ease;
    flex-wrap: wrap;
    margin: 24px 0 0 0;
    padding: 0;
    text-align: left;
}
.navigation-list .nav-tabs .nav-link{
    font-weight: 400;
    text-transform: capitalize;
    color: #fff;
    font-size: 14px;
    border-radius: 5px 5px 0 0;
    padding: 10px;
}
.navigation-list .nav-tabs .nav-link.active{
    color: #0077b6;
    border-bottom: none;
}
.navigation-list .nav-tabs .nav-link:hover{
    color: #0077b6;
    border-bottom: none;
    background-color: #fff;
    
}

.single-document-name{
    text-decoration: none;
    font-size: 17px;
    cursor: pointer;
    color:#2d51a5;
}

.single-document-name:hover{
    text-decoration: underline;
}

.document-banner-button{
    flex: 0 1 60px;
    color: #fff;
    cursor: pointer;
    margin: 24px 0 0 0;
}

.document-banner-tab-container{
    width: 90%;
    justify-content: space-between
}

@media screen and (min-width: 320px) and (max-width: 767px){
    div.resource-grid {
        grid-template-columns: 1fr;
}

}

/* --------------------------- question bank css */

.question-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
    text-align: center;
}

.question-child {
    position: relative;
    transition: 0.1s ease;
}

.question-child:hover .question-content {
    transform: scale(1.0);
    background: #fff;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 3px 4px 13px 2px #ddd;
}

.question-content {
    padding: 30px;
}

.question-child h3 {
    font-size: 1.45em;
}

.icon-container {
    position: absolute;
    top: 8px;
    right: 8px;
    display: none;
}

.question-child:hover .icon-container {
    display:block !important;
}

.icon {
    color: #555;
    cursor: pointer;
    transition: color 0.2s ease;
}

.icon:hover {
    color: #0b6bcb;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
    .question-grid {
        grid-template-columns: 1fr;
    }
}
.resource-child-edit-div.icon-container{
    display: none;
}

.q-bank-heading{
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    margin-top: 52px;
}

.ques-list{
    color: blue;
}

.bg-light{
    background-color: #1976d2;
}

/* .q-bank .accordion-header-li{
    background-color: #0077b6;
} */

.q-bank .coll{
    background-color: #e6f6ff;
}

.qbank-icon{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

/* .accordion-button.collapsed:not(.collapsed){
    background-color: #e6f6ff;
} */
.q-bank.accordion{
    margin-bottom: 10px;
}
.main-articles-list{
    padding: 35px 0 0;
}

.main-article-folder-title div span{
    margin-right: 20px;
}

.main-article-folder-title div span i{
    font-size: 26px;
}

.main-article-folder-title div h4 {
    margin: 0; 
    align-content: center;
}

.folder-document-main {
    gap: 5px; 
    align-items: center; 
}

.folder-document-main-button {
    font-size: 14px !important;
    font-weight: 400 !important;
    height: 1rem !important;
    padding: 10px !important;
}

.folder-document-list{
    margin: 10px;
}

.folder-document-list ul {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}

.folder-document-list ul li {
    margin: 5px 0;
    padding: 0 10px; 
    flex: 0 1 45%;
}

.no-question-available-main {
    margin-top: 5rem;
}

.no-question-available-main img {
    width: 8rem;
}

.no-question-available-main span {
    font-size: x-large;
    color: darkgray
}

.no-question-available {
    margin-top: 3rem;
}

.no-question-available img {
    width: 8rem;
}

.no-question-available span {
    font-size: x-large;
    color: darkgray
}

.folder-document-folders {
    margin: 15px;
    padding: 20px 0 0;
}

.folders-list-container{
    padding-bottom: 38px;
    border-bottom: 1px solid #dadae7;
    margin-bottom: 38px;
}

.folders-list-container-title div span{
    margin-right: 20px;
}

.folders-list-container-title div span i {
    font-size: 24px;
}

.folders-list-container-title div h5 {
    margin: 0;
    align-content: center;
}

.folders-list-container-title .folders-create-document {
    font-size: 12px;
    font-weight: 400;
    padding: 8px;
}

.folders-list-document-lower{
    margin: 10px;
}

.folders-list-document-lower ul{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}

.folders-list-document-lower ul li {
    margin: 5px 0;
    padding: 0 10px;
    flex: 0 1 45%;
}

.folder-list .infinite-scroll-component{
    overflow: hidden!important;
}

.knowledge-base-banner-search {
    width: 30rem;
    height: 50px;
    padding: 0 16px 0 60px;
    background: #fff;
    outline: none;
    border-radius: 8px;
    font-size: 20px;
    color: #747474;
    border: 3px solid transparent;
    transition: border .3s ease;
}

.knowledge-base-banner-s-container{
    position: relative;
}

.knowledge-base-banner-s-container .search {
    position: absolute;
    z-index: 9;
    top: 9px;
    left: 18px;
    font-size: 24px;
    color: #808a98;
}

.knowledge-base-banner-s-container .close {
    position: absolute;
    z-index: 9;
    top: 9px;
    right: 18px;
    font-size: 24px;
    color: #808a98;
}

.resource-child.question-child .question-content svg{
    width: 64px;
    height: 64px;
}

.resource-child.question-child .question-content p{
    font-size: 1.17em;
    font-family: "Muli", sans-serif;
    color: #2f2f2f;
    outline: none;
    font-weight: 600;
}
.resource-child.question-child .question-content p:nth-child(3){
    font-weight: 100;
    color: gray;
    font-size: 1em;
}
.resource-grid.question-grid{
    margin:0 25px;
}
.resource-child.question-child .question-content div button svg{
    width: 24px;
    height: 24px;
}

.resource-child.question-child .question-content div{
    transform: none;
    gap: 0 !important;
}

.resource-child.question-child .question-content div button{
    padding: 0 0 0 4px;
}

.question-child .icon-container button{
    padding: 8px;
}

.question-child .icon-container button svg {
    padding-right:0;
}

.custom-create-folder-btn{
    width: 20% !important;
}

.custom-create-folder-btn.btn-primary{
    background-color: var(--variant-solidHoverBg, var(--joy-palette-primary-solidHoverBg, var(--joy-palette-primary-600, #185EA5))) !important;
    color: white !important;
}

.custom-create-folder-btn.btn-primary:hover{
    background-color:var(--variant-solidHoverBg, var(--joy-palette-primary-solidHoverBg, var(--joy-palette-primary-600, #185EA5))) !important;
}

.document-delete-modal .grad1.modal-header{
    background-color: #0077b6;
}

.document-delete-modal .modal-footer{
    justify-content: center;
    border-top: none;
}

.document-delete-modal .btn.btn-secondary.delete-btn {
    background: rgb(255, 255, 255);
    color: #0077b6;
    border: rgb(255, 255, 255);
    box-shadow: 0 2px 6px #00000029;
}

.document-delete-modal .btn.btn-secondary.cancel-btn {
    background: #0077b6;
    color: rgb(255, 255, 255);
    border: #0077b6;
    width: 40% !important;
}

.delete-document-btn{
    border: none;
    background: transparent;
    font-size: 1.5rem;
    color: #0B6BCB;
    border: none;
    margin-left: 10px;
}

.session-scroll-wrapper{
    height: 325px;    
}

@media screen and (min-width:1025px){
    .session-scroll-wrapper{
        height: 215px;    
    }
}
/* Dropdown Styles */
.dropdown-kb {
    position: absolute;
    background: white;
    border: 1px solid #ddd;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
  }
  
  .dropdown-item {
    padding: 10px;
    cursor: pointer;
    transition: background 0.2s ease-in-out;
  }
  
  .dropdown-item:hover {
    background: #f5f5f5;
  }
  
  .dropdown-item:active {
    background: #007bff;
    color: white;
  }
  
  .session-details-score-custom{
    background-color: red;
    /* padding: 2px; */
    height: 18px;
    border-radius: 5px;
    color:white;
  }
  
  .coe-selection-icon {
    padding: 0.5rem;
    border: 1px solid #ced4da;
    border-radius: 4px 0 0 4px;
 }
  
 .coe-selection-form {
    width: 12rem;
    border-radius: 0 4px 4px 0;
    border-left: none;
    cursor: pointer;
 }
