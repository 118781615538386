* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body, html {
  height: 100%;
  font-family: 'Rubik', sans-serif;
}

.login-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
}
.login{
  font-family: 'Rubik', sans-serif;
  color:#479ef5;
}

.welcome-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  font-family: 'Rubik', sans-serif;
  align-items: center;
  justify-content: center;
  /* padding: 20px; */
  background: url('../../assets/images/Banner_square.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.welcome-text {
  display: contents;
  text-align: center;
}

.welcome-message{
  margin-top: 3rem !important;
  color: white;
  font-size: medium;
}

.form-label{
  color: #479ef5;
  font-family: 'Rubik', sans-serif;
}

.logo-container {
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  align-items: center;
}

.logo {
  height: 40px; /* Adjust the size as needed */
}

.welcome-back {
  font-size: 2.5rem;
  color: white;
  letter-spacing: 2px;
  font-weight: 400;
  /* margin-bottom: 1rem; */
}

.welcome-tagline{
  color: white;
  font-size: small;
  max-width: 70%;
  margin-top: 1rem;  
}

.login-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin-top: 2rem;
}

.login-section h2 {
  font-size: 1.5rem;
  color: #479ef5;
  font-weight: 600;
}

.login-section form {
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 400px;
}

.input-group {
  /* position: relative; */
  /* margin: 0.5rem 0; */
  background: #f0f0f0;
}

.input-group input {
  width: 100%;
  /* padding: 0.75rem; */
  background: #f0f0f0;
  border-left: 5px solid #479ef5;
  padding-left: 1.5rem;
}

.input-group input:focus {
  background: #f0f0f0;
  border-left: 5px solid #479ef5;
}

.toggle-password-btn {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
}

.options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
}

.options label {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
}

.options input {
  margin-right: 0.5rem;
}

.options a {
  color: #479ef5;
  text-decoration: none;
  font-size: 0.9rem;
}

.btn-container{
  display: flex;
  justify-content: center;
}

.btn-submit {
  /* padding: 0.75rem; */
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  height: 40px;
  width: 90%;
  background: #479ef5;
  letter-spacing: 2px;
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 1rem;
  opacity: 1;
}

.btn-submit:hover {
  background: #479ef5;
  color: white;
}

.error-message {
  color: red;
  height: 18px;
  font-size: 0.8rem;
}

.authentication-header.header-sign {
  background-position: center;
  background-repeat: no-repeat;
  object-fit: cover;
  background: #479ef5;
}

.sign-btn .spinner-border {
  width: 1.2rem;
  height: 1.2rem;
  border-width: 0.17em;
}

.slash {
  height: 6px;
  width: 30px;
  background: white;
  border-radius: 5px;
}
.additional-links{
  position: absolute;
    bottom: 20px;
    left: 20px;
    width:45%;
    display: flex;
    justify-content: space-around;
}

/* .link-1{
  width: 100%;
  color: white;
  font-size: small;
  display: flex;
  justify-content: space-around;
}

.link-2{
  width: 100%;
  color: white;
  text-decoration: none;
  font-size: small;
  display: flex;
  justify-content: space-evenly;
} */
