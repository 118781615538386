.accod-header div{
    padding-right: 2px;
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
}

.accod-header .accordion-button{
    padding: 0px;
    height: 23px;
}
.accod-header .accordion-button::after{
    background-size: 17px;
    background-image: url('../../../assets/images/blue-play.png');
    /* background-size: cover; */
    background-repeat: no-repeat;
    margin-top: 2px;
    margin-left: 2px;
}

.accod-header .accordion-button:not(.collapsed)::after{
    margin-top: 11px;
    margin-bottom: 13px;
}

.time-stamps{
    color: #5f5f5f;
}
.time-stamps img{
    height: 18px;
    margin-left: 10px;
}

.accod .accod-body{
    padding: 0px;
    padding-top: 8px !important;
    display: flex;
    flex-direction: column;
}

.accod .accod-body div{
    font-size: 12px;
    padding-left: 10px;
    margin-left: -3px;
    margin-bottom: 4px 
}