table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.odio-table.table.sticky .header {
  top: 0;
  box-shadow: none !important;
  background: #0077B6 !important;
  color: #fff;
}
/* table tr td, table tr th{
  width: calc(100% / 8);
}
table tr:nth-child(even){background-color: #f2f2f2;}

table tr:hover {background-color: #ddd;} */

.hypcqe .table .th{
  padding: 0.4rem 0.5rem !important;
  background-color: #0077B6 !important;
}
.hypcqe .table .th, .hypcqe .table .td {
  padding: 0.4rem 0.5rem !important;
}
.hypcqe .table.sticky {
  overflow-y: hidden !important;
  overflow-x: auto !important;

}
.odio-tablefooter .btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #0077B6;
  border-color: #0077B6;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.odio-paginated-details{
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #0077B6;
  border-color: #0077B6;
}

.btn-check:active+.btn-outline-secondary:focus, .btn-check:checked+.btn-outline-secondary:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus, .btn-outline-secondary:active:focus {
  box-shadow: none;
}
.btn-check:focus+.btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: none;
}
.odio-tableTop .excel{
  border-end-end-radius: 0;
  border-start-end-radius: 0;
}
.odio-tableTop .pdf{
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}
.hypcqe .table.sticky [data-sticky-last-left-td] {
  box-shadow: 0px 0px 0px #ccc !important;
}
.hypcqe .table.sticky [data-sticky-first-right-td] {
  box-shadow: 0px 0px 2px #ccc !important;
}

.btn{
  line-height: 1.2;
}
.odio-group-scroll::-webkit-scrollbar {
  height: 5px;
  background-color: #f5f5f5;
}
.odio-group-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(160, 158, 158, 0.3);
}
.odio-group-scroll::-webkit-scrollbar-thumb {
  background-color: rgb(145, 146, 146);
  border-radius: 10px;
}

/* Ravinder Css */
.password-content>div .input-group span i{
  margin-right: 0px;
}
.view-team-card-details>div table tbody tr td{
color: #212529;
}
.view-team-card-details table tbody tr td{
  color: #212529;
}
.cxTYC .table .th, .cxTYC .table .td {
  background-color: transparent !important;
}
.cxTYC .table.sticky {
  overflow: auto !important;
}


.react-table table tr:hover {
  background-color: transparent;
}
.react-table table tr:nth-child(even) {
  background-color: transparent;
}
.react-table table {
  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
  border-collapse: separate !important;
  border-spacing: 0;
}
.react-table table{
  border-left: 1px solid #dee2e6;
}
tbody, td, tfoot, th, thead, tr {
  border-color: #dee2e6;
  border-style: solid;
  border-width: 0;
}
.react-table tbody td, .react-table thead th {
  border-bottom-width: 1px;
  border-right-width: 1px;
}
.react-table table thead{
  background: #0077B6;
  color: #fff;
  font-weight: normal;
}
.react-table table thead th{
  font-weight: normal;
}
.react-table thead th, .react-table tbody td {
  padding: 0.4rem 0.5rem;
}
.react-table tbody td:nth-last-child(1) {
  text-align: center;
}
.react-table p i{
  font-size: 14px !important;
}

.reports .react-table tbody td:nth-last-child(1) {
  text-align: left;
}
