.side_nav_scroll{
    overflow-y: auto;
    height: 90vh;
}

.side_nav_scroll::-webkit-scrollbar
{
    width: 8px;
    background-color: #F5F5F5;
	
}
.side_nav_scroll::-webkit-scrollbar-track
{
    box-shadow: inset 0 0 6px rgba(160, 158, 158, 0.3);
	/* position: relative;
	right: -10px; */
  }

.side_nav_scroll::-webkit-scrollbar-thumb
{
    background-color: rgb(145, 146, 146);
    /* outline: 1px solid rgb(68, 68, 68); */
	border-radius: 40px;
}

.tabs{
    border-bottom: 0.2px solid rgb(145, 146, 146);
    border-left: 0.2px solid rgb(145, 146, 146) ;
    border-right: 0.2px solid rgb(145, 146, 146);
}

.modal-footer .btn.btn-secondary{
    background-color: #e7eaf3;
    width: unset !important;
    border: none;
    color: #212529;
    line-height: 1.5;
}
.modal-footer .btn.btn-primary{
    background-color: #0077b6;
    color: white;
    width: unset !important;
}
.logo-avatat-clint{
    width: 35px;
    height: 35px;
    align-items: center;
    background: #0077b6;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    cursor: pointer;
}
/* #country-select.MuiInputBase-input{
    text-align-last: justify;
    padding-right: 28px !important;
} */
.top-network-error{
    position: fixed;
    top: 0;
    left: 263px;
    padding: 5px 20px;
    background-color: #f52020;
    color: #fff;
    height: 30px;
    width: 100%;
    z-index: 999;
}
.top-network-error+.topbar{
    top:30px;
}
.top-error~.page-wrapper{
    margin-top: 90px;
}

.link{
    color: blue;
}

.full-search-bar {
    display: flex;
    align-items: center;
    /* width: auto; */
    width: 85%;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 100;
    background: #ffffff;
    border-bottom: 1px solid rgb(228 228 228 / 0%);
    background-clip: border-box;
    height: 61px;
    /* padding: 0rem 1.4rem; */
    padding-left: 3rem;
}
.search-bar-box {
    width: 100%;
}
.search-control {
    background-color: #fff;
    border: 1px solid #f2efef00;
    padding-left: 2.5rem;
}
.search-show {
    font-size: 18px;
    left: 15px;
}
.search-close {
    font-size: 24px;
    right: 10px;
    cursor: pointer;
}
.search-dropdown {
    position: absolute;
    background: #fff;
    /* padding: 10px 0; */
    border-radius: 0 0 5px 5px;
}
.search-scroller::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }

.search-scroller::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(160, 158, 158, 0.3);
}

.search-scroller::-webkit-scrollbar-thumb {
    background-color: rgb(145, 146, 146);
    border-radius: 40px;
}

.search-dropdown {
    z-index: 2;
    width: 100%;
    border-radius: 0px 0px 15px 15px;
    box-shadow: 0px 6px 18px 4px #20212419;
}   
.wrap-search-btn {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    /* padding: 0 0 0 15px; */
}
.search-more{
    display: flex;
    align-items: center;
    justify-content: center
}
.search-more button{
    border: none;
    background-color: #20212419;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 5px 10px;
}
.matchedResult p {
    width: 45rem; 
    border-radius: 0 20px 20px 0;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    cursor: pointer;
    font-size: 15px;
    color: black;
    font-weight:400;
}

.matchedResult p:hover {
    background-color: #20212419;
}

.matchedResult > .search-result-lines > div:first-child p {
    background-color: #20212419;
}

.wrap-search-btn button{
    cursor: pointer;
    background-color: #fff;
    border: 0;
    border-radius: 0px;
    font-size: 15px;
    color: #738aa0;
    margin-right: 5px;
}

/* .dropdown-item:focus, .dropdown-item:hover {
    background-color: #0077B6;
    color: #fff !important;
} */

.blur-background {
    filter: blur(5px);
    pointer-events: none; /* This will disable interaction with the background */
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999; /* Adjust based on your needs */
    pointer-events: auto;
}

.waveform-controls-div {
    justify-content: space-between;
    align-items: center;
}

.waveform-controls-button {
    font-size: 14px !important;
    font-weight: 400 !important;
    height: 1rem !important;
    padding: 10px !important;
}

.waveform-start-end {
    align-items: flex-end;
    gap: 10px;
}
