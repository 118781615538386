.setting-section-nev .nav-link.active {
    color: #0077B6 !important;
    border-color: #fff #fff #0077B6 #fff !important;
    font-weight: 500 !important;
    background: transparent !important;
}
.setting-section-nev .nav-link {
    font-size: 14px;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    color: #585858;
    border-bottom-left-radius: 0rem;
    background: 0 0;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    margin-bottom: -1px;
    margin-right: 0px;
}
.nav-tabs {
    border-bottom: transparent;
}
.account-content .card.edit-border.setting-card .card-body {
    padding: 1.5rem 1.5rem !important;
}
.msl-vars {
    --menu-max-height: 400px;
    --input-width: 100%;
    --font-size: 16px;
    --border-radius: 4px;
    --active-menu-shadow: 1px 1px 5px 0px #ced4da;
    --line-height: 1.2;
    --active-menu-background: #fff;
    --active-menu-radius: var(--border-radius);
}
.multiple-select{
    width: 100%;
}
.holidays-list-box input{
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.aap-ing-img{
    width: 65px;
    height: 65px;
    border-radius: 50%;
    border: 0px solid #e5e5e5;
    /* background: #e5e5e5; */
    padding: 12px;
}

#primaryAdmin .MuiTableCell-root.MuiTableCell-body{
    padding: 0;
    width: 100%;
    border-bottom: none;
}
#primaryAdmin .MuiTableBody-root .MuiTableRow-root:hover{
    background-color: transparent;
}
#primaryAdmin .MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary{
    padding: 0;
    min-width: 30px;
    width: 30px;
    margin-right: -10px;
    margin-left: 10px;
}

#primaryAdmin .MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary .MuiButton-label i{
    font-size: 28px;
}

#primaryAdmin .MuiButtonBase-root.MuiButton-root.MuiButton-text .MuiButton-label i{
    font-size: 28px;
    line-height: 1;
    margin: 0 0px 0px 0px;
    background: none;
}
.Add-admin-btn .MuiButton-label{
    position: absolute;
    bottom: 0;
    right: -27px;
}
#primaryAdmin table tr:nth-child(even) {
    background-color: transparent;
}
.blank-cell-admin{
    height: 10px;
}
#primaryAdmin table {
    border-spacing: 0 10px;
}
/* Ravinder Css */

.view-team-card-details form>div .input-group span i{
    margin-right: 0px !important;
}
.view-team-card-details form>div p{
    margin-bottom: 0px;
}
.holidays-list-box>div input{
    padding: 9px 10px !important;
}
select{
    padding: 9px 20px 9px 10px !important;
    background-position: right 0.2rem center !important;
}
.form-control{
    line-height: 1.9 !important;
}
.msl-input-wrp .msl-input{
    margin:8px;
}
.setting-page .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #0077B6;
    background-color: #fff;
    border-color: #0077B6 #0077B6 #fff;
}
.setting-page .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link{
    font-weight: 500;
    text-transform: uppercase;
}
.setting-page .nav-link:focus, .nav-link:hover {
    color: #0077B6;
}
.setting-page .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    border-color: #0077B6 #0077B6 #fff;
    isolation: isolate;
}
.setting-page .nav-link {
    display: block;
    padding: 0.5rem 1rem;
    color: #585858;
    text-decoration: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}
.account-content .card.edit-border .card-body.setting-page {
    padding: 0rem 2rem 1rem !important;
}
.account-content .card.edit-border .card-body.setting-page .nav.nav-tabs{
    padding-top: 1rem !important;
}
.react-autosuggest__suggestions-list{
    list-style: none;
    padding-left: 0;
}
.react-autosuggest__suggestions-list .list-group-item:hover{
    background: #0077B6;
    color: #fff;
}
.update-btn .spinner-border, .account-edit-btn.edit-account .spinner-border{
    width: 1.2rem;
    height: 1.2rem;
    border-width: 0.17em;
}
.send-to-details{
    border-top: 1px solid rgb(228, 228, 228);
}
.small-dropdown.rating-dropdown .ms-2{
    margin-left: 0 !important;
}