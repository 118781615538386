.accordion-header-index {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 5px;
}
.accordion-header-index-numbers {
  margin-right: 10px;
}
.mb-2 {
  margin-right: auto;
  width: 100%;
}
/* .accordion-item{
    border:0px 0px 0px 1px solid lightgray;
} */
.accordion-header-index .accordion-header {
  padding-right: 10px;
  /* background-color:#0077B6; */
}
.custom-modal-header .btn-close {
  margin: 0 !important;
}
.modal-form-control {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.form-control-modal {
  width: 15.625rem;
  margin-left: 36rem;
}
.table-header {
  display: flex;
  padding: 5px;
  border-bottom: 1px solid #ccc;
  background-color: #0077b6;
  color: #fff;
  justify-content: space-between;
}
.table-cell {
  flex: 1; /* Ensure each cell takes equal space */
  padding: 5px; /* Add padding for spacing */
  text-align: center; /* Center-align text */
  word-wrap: break-word; /* Ensure long text wraps */
  display: inline-table;
}
.list-row {
  display: flex;
  padding: 5px;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
}
.modal-form-control-container {
  margin-left: auto;
}

.chart-container {
  position: relative;
}

.recommended-label {
  position: absolute;
  top: -20px;
  right: 0;
  background-color: #00e396;
  float: "right";
  color: white;
  padding: 0px 5px;
  border-radius: 5px;
  font-size: 12px;
  text-align: center;
  text-wrap: nowrap;
}

.chart-container {
    position: relative;
  }
  
  .recommended-label {
    position: absolute;
    top: -20px;
    right: 0;
    background-color: #00e396;
    float: "right";
    color: white;
    padding: 0px 5px;
    border-radius: 5px;
    font-size: 12px;
    text-align: center;
    text-wrap: nowrap;
  }