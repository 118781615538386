body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: #0077B6;
}

header .user-box.dropdown a:focus{
  outline: 0;
  box-shadow: none;
}
header .user-box.dropdown a .dropdown-menu.show{
  top: 62px !important;
}
header .user-box.dropdown .dropdown-item a, header .user-box.dropdown .dropdown-item{
  color: #212529;
}

/* input*/
.input-group>.form-control:focus, .input-group>.form-select:focus, .form-control:focus{
  color: #212529;
  background-color: #fff;
  border-color: #ced4da;
  outline: 0;
  box-shadow: none;
}