.fabDiv{
    position: fixed;
    bottom: 40px;
    right:  20px;
    width:  60px;
    height: 60px;
    font-size: 16px;
    font-weight: 400;
    padding: 6px 12px;
    border:none;
    display: flex;
    align-items:center;
    justify-content: center;
    cursor:pointer;
    transition: background-color 0.3s ease;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.fabButton{
    cursor: pointer;
    outline: 0;
    color: #fff;
    background-color: #0073bc;
    border:none;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    border: 1px solid transparent;
    padding: 6px 12px;
    font-size: 16px;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.fabButton:hover {
    background-color: #0056b3;
    width: auto; 
    padding: 0 15px; 
    font-size:10px;
}

.fabText {
    display: none;
    margin-left: 10px; 
}
  
.fabButton:hover .fabText {
    display: inline-block;
}

.bx.bx-play-circle {
    font-size:1.35rem;
}
