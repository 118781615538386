/* .semantic_box{
display: flex;
justify-content: flex-start;
 align-items: center;
 gap: 100px;
} */
/* .btn_semantic {
	border: none;
  padding: 0;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  font-size: 3em;
  color: #fff;
  margin-left: 40px;
    line-height: 50px;
    text-align: center;
    white-space: nowrap;
} */


:root {
  --pink: #f50057;
  --animation-curve: cubic-bezier(0.12, 0.7, 0.74, 0.71);
}
.phone {
  z-index: 2;
  display: grid;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  padding: 10px;
  position: absolute;
  left: 5px;
  top: -6px;
}
.material-icons {
  color: white;
  font-size: 15px !important;
  text-align: center;
  display: inline-block;
  margin : auto 1px;
}
.para{
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: red;
  position: absolute;
}
.para_animation{
  animation: scaling 2s var(--animation-curve) infinite;
}
@keyframes scaling {
  0% {
    transform: scale(1);
    background-color: var(--pink);
  }
  100% {
    transform: scale(1.5);
    background-color: rgba(245, 0, 182, 0);
  }
}

@keyframes phone {
  0% {
    transform: scale(4);
  }
  100% {
    transform: scale(4.5);
  }
}
.circle1 {
  animation-delay: 0s;
}
.circle2 {
  animation-delay: 1s;
}
.parent,.parent1{
  position: relative
}
.container_semantic {
  margin-right: 30px;
}
.parent1{
 margin-left: 4rem;
}
.semantic_box{
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.call-end-start-button {
  padding: 5px !important; 
  font-size: 14px !important; 
}

.call-end-start-icon{
  font-size: 18px !important;
  margin-left: 5px !important;
} 