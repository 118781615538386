.moment-list-group-scroll {
    height: 67vh;
    overflow: auto;
  }
  .list-hover-input-group {
    text-align: right;
  }
  /* i {
    color: #0077b6;
  } */
  .feedback-headder .feedback-heading {
    flex: auto;
    /* margin-top: 10px; */
    margin-left: 2px;
  }
  .feedback-fillter .input-group-text {
    padding: 0 0.5rem;
    font-size: 20px;
    height: 40.5px;
  }
  .accordion-item:first-of-type {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .accordion-item {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
  }
  .accordion-header {
    margin-bottom: 0;
  }
  .wave {
    display: block;
    position: relative;
    user-select: none;
    height: 20px;
    cursor: auto;
    overflow: auto hidden;
  }
  .canvas {
    position: absolute;
    z-index: 2;
    left: 0px;
    top: 0px;
    bottom: 0px;
    height: 100%;
    pointer-events: none;
    width: 1486px;
  }
  .moment-table-hover-icon {
    font-size: 16px;
    font-weight: 500;
    color: #32393f;
  }
  .table-group-header {
    align-items: center;
  }
  .moment-table-hover-icon
  .table-group-heading {
    align-items: center;
  }
  .btn-mdl {
    background-color: #2648a9;
    border-color: #2648a9;
    font-size: 15px;
  }
  .modal-dialog .modal-content .modal-header {
    border-bottom: 1px solid rgba(0,0,0,.125);
  }
  .modal-dialog1 .modal-content {
    background-color: #0077b6;
    width: 883px;
  }
  .modal-dialog .modal-content .modal-body .modalcard .modalcard-body>div>div>ul li {
    background-color: #0077b6;
  }
  .modal-dialog .modal-content .modal-body .modalcard .modalcard-body>div>div>ul li i {
    color: #fff;
  }
  .modal-dialog .modal-content .modal-body .modalcard .modalcard-body>div>div>ul li h6 {
    color: #fff;
  }
  .modal-dialog .modal-content .modal-body .modalcard .modalcard-body>div>div>ul li span {
    color: #fff;
  }
  .accordion.accordian-item.accordion-header.accordion-button:focus {
    z-index: 3;
    /* border-color: #525f7f; */
    outline: 0;
    box-shadow: none;
  }
  .accordion-button:focus {
    box-shadow: none
  }
  .moment-group-table table tr:hover {
    background-color: none;
  }
  .list-like-before-hidden{
    display: none;
  }
  .upload-download-button{
    display:flex;
    align-items:center;
    margin-right: 16px;
    gap:2px;
  }

  .call-upload-btn{
    position: fixed;
    right: 39px;
    bottom: 60px;
  }
  .call-upload-btn button{
    background-color: #0077b6;
    font-size: 20px;
    padding: 7px 8px 9px 12px;
  }

  .glId-upload{
    position: fixed;
    right: 100px;
    bottom: 60px ;
  }
  .glId-upload Button{
    background-color: #0077b6 !important;
    font-size: 20px;
    border-color: #0077b6 !important;
    padding: 7px 8px 9px 12px;
  }

  .salesCallCard .user{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .salesCallCard .user .caller{
    width: 16%;
  }

  .agentRecom .rec-slider{
    left: 10px!important;
  }
  .agentRecom .rec-carousel-wrapper .rec-carousel .rec-arrow{
    margin-left: -14px;
  }

  .caller .name span {
    vertical-align: top;
    padding-right: 5px;
  }
  .caller .name .text {
    font-size: 16px;
    font-weight: 500;
    color: #272727;
    text-transform: capitalize;
  }
  .caller .rank {
    padding-left: 23px;
    color: #0073BC;
    font-weight: 500;
  }
  .callCount {
    display: flex;
  }
  .callCount .box {
    padding: 5px;
    border-radius: 10px;
    font-size: 14px;
    margin: 5px;
  }
  .callCount .box:nth-child(1) {
    border: 1px solid #8A00FF;
    color: #8A00FF;
  }
  .callCount .box:nth-child(2) {
    border: 1px solid #0075FC;
    color: #0075FC;
  }
  .callCount .box:nth-child(3) {
    border: 1px solid #FF9300;
    color: #FF9300;
  }
  .callCount .box img {
    padding-right: 5px;
    width: 18px;
  } 
  .callCount .box span {
    vertical-align: text-top;
  }
  .score {
    display: flex;
    /* width: 25%; */
    justify-content: end;
  }
  .scoreCommonStyle {
    padding-right: 10px;
  }
  .scoreCommonStyle .text {
    font-size: 10px;
    line-height: 10px;
    font-weight: 600;
    display: inline-block;
    padding-right: 3px;
  }
  .scoreCommonStyle .badge {
    /* background: #18CC11 0% 0% no-repeat padding-box; */
    border-radius: 10px;
    padding: 8px;
    font-size: 14px;
    vertical-align: super;
  }
  .seller {
    display: flex;
    justify-content: space-between;
  }
  .seller .name {
    color: #0073BC;
    text-transform: capitalize;
  }
  .seller>div span {
    vertical-align: text-bottom;
    padding-right: 5px;
  }

  .card-container {
    position: relative;
    cursor: pointer;
    margin-bottom: 0.5rem;
  }
  
  .card-arrow {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 15px;
    border-radius: 0 0 15px 15px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    overflow: hidden;
  }
  
  .arrow-down {
    bottom: -15px;
  }
  
  .arrow-icon {
    color: #0077b6;
    font-size: 20px;
    margin-top: -4px; /* Adjust this value to properly position the icon */
  }
  

  .second-card{
    margin-top:20px;
  }

  .alignRevert {
    vertical-align: revert;
  } 
  .sortList{
    position:absolute;
    inset: -34px auto auto 13px;
    margin:0px;
    transform: translate(274px, 156px);
  }

  .toggled .sortList{
    position:absolute;
    inset: -62px auto auto 13px;
    margin:0px;
    transform: translate(81px, 156px);
  }

  .call-upload-btn Button{
    background-color: #0077b6 !important;
    border-color:#0077b6 !important;
  }

  .exportExcel{
    display:flex;
    align-items:center;
    justify-content:center;
    border: none;
    border-radius: 5px;
    /* background: #0077b6; */
    color: #0077b6;
    width: 40px;
    height:33.195px;
    font-size: 30px;
    /* margin-right: 13px; */
  }


  .shadow{
    margin: 4px;
    border-radius: inherit;
    z-index: 1;
    box-shadow: 5px 0px 8px 4px yellow !important
  }

  .shadow-call-moments{
    margin: 4px;
    border-radius: inherit;
    z-index: 1;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important
  }

  .list-group-item-action-trans{
    width: 99%;
    color: #495057;
    text-align: inherit;
  }

  .popover-body {
    /* max-height: 600px; */
    overflow-y: auto;
  }
   
  .conv-download-button{
    padding-right: 50px;
  }

  .conv-download-button-icon{
    color: #0077b6;
    font-size: 20px;
    cursor: pointer;
  }
  /* .list-group-item::after{ 
    content: '';
    position: absolute;
    top: -5px; 
    right: -5px;
    bottom: 2px;
    left: -5px;
    z-index: 1;
    box-shadow: 2px 2px 4px yellow;
  } */


  