.skeleton-card-item{
    width: calc(25% - 12px); 
    box-sizing: border-box;
    background-color: #ecf0f3;
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid #eee;
    margin-bottom: 0.5rem;
}

.skeleton-cards-container{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap:10px;
    /* justify-content: space-between; */
    padding: 10px 10px 45px 35px;
}

.toggled .skeleton-cards-container{
    padding-left: 15px;
    gap:10px;
}

.cards-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap:10px;
    /* justify-content: space-between; */
    padding: 10px 10px 45px 35px;
}

.meeting-title{
    padding-left: 15px;
}

.toggled .meeting-title{
    padding-left: 5px;
}

.toggled .cards-container {
    padding-left: 15px;
    /* gap:3px; */
    gap:6px;
}

.meeting-headings{
    font-size: 26px;
    line-height: 0.9;
    padding: 20px 0px 10px 10px;
}

.nodatafound-container{
    margin: auto;
    height: 72vh;
    align-items: center;
    display: flex;
    justify-content: center;
}

.card-item {
    /* padding-left: 15px;
    padding-top: 15px; */
    /* width: calc(25% - 12px);  */
    box-sizing: border-box;
    background-color: #ecf0f3;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px; */
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid #eee;
    margin-bottom: 0.5rem;
}

.card-item:hover{
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
}
/* .card-item:nth-child(4n) { */
    /* padding-right: 15px; */
/* } */

/* Adjust the width for different screen sizes */
@media (max-width: 1200px) {
    .card-item {
        width: calc(33.33% - 15px); /* Three cards in a row */
    }
    .card-item:nth-child(3n) {
        padding-right: 15px;
    }
}

@media (max-width: 900px) {
    .card-item {
        width: calc(50% - 15px); /* Two cards in a row */
    }
    .card-item:nth-child(2n) {
        padding-right: 15px;
    }
}

@media (max-width: 600px) {
    .card-item {
        width: calc(100% - 15px); /* One card in a row */
    }
    .card-item:nth-child(1n) {
        padding-right: 15px;
    }
}

.card-meeting{
    border-radius: 50px;
    
}

.card-recording-detail p {
    margin-bottom: 0;
}

.card-recording-detail-name {
    font-weight: 500;
    color: #655959;
}

.card-recording-detail-time {
    font-size: 12px;
    color: #969393;
}

.card-recording-detail-recorder {
    font-size: 12px;
    color: #969393;
}
.p-text-change{
    font-weight: 500;
}

.divider {
    margin: 6px 0px 6px 0px
}

.card-client {
    display: flex;
    flex-direction: row;
}

.card-client-detail {
    font-size: 12px;
    color: #5c5050;
    width: 50%;
}

.card-client-detail-title {
    font-weight: 500;
    color: #717272;
}

.card-client-detail p {
    margin-bottom: 0;
}

.card-participants {
    font-size: 12px;
    font-weight: 600;
    color: #717272;
}

.card-participants-text {
    font-size: 12px;
    color: #747373;
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%;
}

.card-buttons {
    display: flex;
    justify-content: space-between;
    padding-top: 0px;
}

.card-buttons .card-buttons-name {
    font-weight: 600 !important;
    font-size: 11.5px !important;
}

.options-card {
    position: absolute;
    background-color: #ffffff;
    box-shadow: 0 2px 10px #0000001a;
    border-radius: 4px;
    padding: 8px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    margin-top: -66px;
    gap: 2px;
  }
  
  .options-button{
    margin: 0 !important;
    font-size: 11.3px !important;
    font-weight: 600 !important;
  }

  .meeting-upload-btn{
    position: fixed;
    right: 30px;
    bottom: 60px;
  }

  .meeting-upload-btn Button{
    background-color: #0077b6 !important;
    border-color:#0077b6 !important;
  }

  .meeting-upload-btn Button i{
    color: white;
    padding: 4px 0px;
  }

  .adhoc-short-title{
    font-size:14px;
    font-weight:500;
  }

  .adhoc-input-btn-container {
    display:flex;
    border:1px solid black;
    border-radius:5px;
    margin:26px 0px;
  }

  .adhoc-input {
    width:450px;
  }

  .adhoc-paste-button{
    background-color: transparent;
    font-size:14px;
    font-weight:700;
    padding:0px 10px 0px 15px;
    margin:6px 0px;
    border-bottom:none;
    border-top:none;
    border-right:none;
    border-left:2px solid #747373;
    color:#0B6BCB;
  }

.adhoc-join-button button{
    border: none;
    background-color: #0B6BCB;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
}

.meeting-play-pause-button{
    border: none;
    background: none;
    color: #1976d2;
}

.meeting-play-pause-icon{
    font-size: 25px;
    height: 0;
    padding: 0 2px 0 2px; 
}

.meeting-play-pause-button:hover{
    background-color: #cad0f6;
    border-radius: 12px;
}







