.session-info-header{
    width: 100%;
    background-color: rgb(16, 62, 107);
    position: relative;
    display: flex;
    justify-content: center;
    padding: 2rem;
}
.session-banner{
    display: flex;
    gap: 2rem;
    height: 100%;
    flex-wrap: wrap;
    justify-content: center;

}
.session-post-1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.5rem;
    color: rgb(255, 255, 255);
    min-height: 2.5rem;
    flex: 1 1 0%;
}
.session-subheading{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.session-subheading-blockquote{
    width: fit-content;
    margin-bottom: 1rem;
    cursor: default;
}
.session-subheading-blockquote .category{
    font-size: 1.802rem;
    font-weight: 700;
}
.session-subheading-blockquote .second-span{
    width: fit-content;
}
.additional-info-container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.restrictions-wrapper{
    align-items: center;
    display: flex;
    gap: 1rem;
}
.info-img{
    height: 2rem;
}
.restriction-item{
    display: flex;
    align-items: center;
    gap: 0.5rem;
    transition: background-color 0.2s ease-in;
}
.restriction-item-icon{
    height: 2rem;
    width: 2rem;
    background-color: rgba(116, 176, 234, 0.25);
    border-radius: 5px;
}
.text-information{
    font-size: 0.889rem;
    font-weight: 400;
}
.text-container{
    display: flex;
    flex-direction: column;
}
.clickable-text{
    text-decoration: none;
    cursor: pointer;
    font-weight: 700;
}
.session-post-2{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
    min-width: 400px;
    width: 400px;
    color: rgb(255, 255, 255);
    min-height: 2.5rem;
    align-items: normal;
}
.session-post-2 img{
    width: 100%;
    min-height: 275px;
    aspect-ratio: 3 / 2;
}
.sessions-direct-course{
    width: 100%;
}
.sessions-direct-course button.start-button {
    width: 100%;
    background-color: rgb(255, 255, 255);
    font-weight: 700;
    border-color: transparent;
    padding: 0.5rem;
    border-radius: 4px;
}
.sessions-direct-course .btn-text {
    display: inline-flex;
}
.solid.icon-before{
    position: relative;
    font-size: 0.889rem;
    height: 2.5rem;
    min-width: 2.5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0px 1.75rem;
    border-radius: 5px;
    line-height: 1.125rem;
    cursor: pointer;
    padding-block: 0px;
    padding-inline: 1.25rem 1.75rem;
    color: rgb(255, 255, 255);
    background-color: rgba(116, 176, 234, 0.25);
    border: 1px solid transparent;
}
.solid.icon-before svg{
    margin-inline-end: 0.5rem;
}
.solid.icon-before .btn-text {
    display: inline-flex;
}
.session-description{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    padding: 3rem;
    align-items: start  ;
}
.description{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-break: break-word;
}
.expanded{
    -webkit-line-clamp: 20;
}
.linkButton {
    color: rgb(27, 104, 179);
    background-color: transparent;
    border: none;
}
.options-coaching{
    margin: 1rem 0;
    padding: 0;
}

.options-coaching label{
    display: flex;
    align-items: center;
    font-size: 0.9rem;
}

.options-coaching input{
    margin-right: 5px;
}