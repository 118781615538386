.filter_scroll_summary{
    overflow: auto;
    height: 900px;
  }
  .filter_scroll_summary::-webkit-scrollbar
  {
      width: 8px;
      background-color: #F5F5F5;
      
  }
  .filter_scroll_summary::-webkit-scrollbar-track
  {
      box-shadow: inset 0 0 6px rgba(160, 158, 158, 0.3);
    }
  
    .filter_scroll_summary::-webkit-scrollbar-thumb
  {
      background-color: rgb(145, 146, 146);
        border-radius: 40px;
  }

.toggled-details {
    overflow: hidden;
    transition: max-height 0.6s ease-out, opacity 0.6s ease-out;
  }
  
  .toggled-details.collapsed {
    max-height: 0;
    opacity: 0;
  }
  
  .toggled-details.expanded {
    max-height: 300px; /* adjust this value based on the content's max height */
    opacity: 1;
  }

  .meeting-filter-hr{
    margin-top: -5px;
    margin-bottom: 10px;
  }
  
  .meeting-filter-input-size{
    width: 200px;
    border-radius: 6px !important;
    height: 40px;
  }