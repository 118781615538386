.PrivateSwitchBase-root-9 {
    padding: 0px !important;
}
.pagination-container {
    display: flex;
    list-style-type: none;
    justify-content: flex-end;
}
.apexcharts-toolbar{
    margin-top: -60px;
    z-index: 0 !important;

}
.custom-bar-icon {
    margin-right: 10px; 
}

.custom-bar-icon,
.custom-line-icon,
.custom-view-icon {
  cursor: pointer;
  transform: scale(1.5);
}

.custom-bar-icon.select,
.custom-line-icon.select {
  background-color: #0077b6;
  box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.13);
  border-radius: 2px;
  width: 20px;
  height: 20px;
  color: #fff;
}

.custom-line-icon {
    margin-right: 10px; 
}
.apx-legend-position-bottom{
    position: absolute;
    max-height: 45px !important;
}

.apexcharts-text.apexcharts-xaxis-title-text {
    margin-top: 20px;
}

.error-message{
    color: red;
    font-size: 12px;
}

.insights-agent .MuiFormControl-root .MuiOutlinedInput-root{
    padding: 8px!important;
} 
.insights-agent .MuiOutlinedInput-root.MuiInputBase-root {
    border-radius: 5px!important;
}
.insights-agent .css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator{
    right: -10px;
}
.insights-agent .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment{
    right: 3px;
}
.insights-agent .css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator:hover{
    right: -10px;
} 

.insights .MuiFormControl-root .MuiOutlinedInput-root{
    padding: 0px!important;
}
.insights .MuiOutlinedInput-root.MuiInputBase-root {
    border-radius: 5px!important;
}

.insights .MuiOutlinedInput-root .MuiSelect-select {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
}

#menuItems.MuiMenuItem-root.MuiButtonBase-root:hover {
    background-color: #aee3ff !important;
    color: #fff;
}

.border-bottom {
    border-bottom:'1px solid #ccc'
}

.date-filter .css-qnu0e5-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
    width: 100%;
} 

.tooltip-custom{
    background-color:#fff;
    color:#000 ;
    padding: 5px;
    border-radius: 5px;
    font-size: 12px;
}