body{
    height: 100vh;
}

.navbar1{
    padding-left: 10px;
}
.moment-matches-text{
	line-height: 1;
    padding: 0px 8px;
}
.moment-matches-text .btn i{
	font-size: 9px !important;
    font-weight: 400;
    margin-top: -10px;
    margin-left: 5px;
    border: 1px solid #b9baba;
    border-radius: 50%;
    padding: 1px;
}
.btn-outline-blue {
    color: #0077B6;
    border-color: #0077B6;
}
.btn-outline-blue:hover {
    color: #0077B6;
    border-color: #0077B6;
}
.add-moment-btn:hover{
    color: #0077B6;
}
.del-moment-group i{
    font-size: 14px !important;
}
.input-field-bt-border{
	border-top: none;
	border-left: none;
	border-right: none;
	border-bottom-width: 1px;
}
.input-field-bt-border:focus-visible, .input-field-bt-border:focus, .input-field-bt-border:active,
.input-field-bt-border:focus-within, .input-field-bt-border:hover{
	border-top: none;
	border-left: none;
	border-right: none;
	border-bottom-width: 1px;
	outline: none;
    box-shadow: none;
}
.manage-moment-head{
	position: sticky;
    /* top: 55.5px; */
    top: 55.4px;
    padding: 15px 0px 0 0;
    z-index: 9;
    background: #fff;
    margin: 0 -10px;
    box-shadow: 0 1px 0px 0 rgb(218 218 253 / 65%), 0 0px 0px 0 rgb(206 206 238 / 54%);
}
/* .editable-nameInput-mmt, .gp-editable-nameInput-mmt{
	border: none;
	display: none;
} */
.editable-nameInput-val, .gp-editable-nameInput-val{
	font-size: 18px;
	color: #000;
}
/* .manageMoment-container{
	height: 84vh;
	overflow: auto;
} */
.manageMoment-container::-webkit-scrollbar {
    width: 8px;
    background-color: #F5F5F5;
	
}
.manageMoment-container::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(160, 158, 158, 0.3);

	/* position: relative;
	right: -10px; */
}
.manageMoment-container::-webkit-scrollbar-thumb {
	background-color: rgb(145, 146, 146);
	border-radius: 10px;
}

.account-content .btn.account-edit-btn.edit-account {
    background-color: #0077b6;
    border-top-right-radius: 0.25rem;
    line-height: 1.25;
    font-size: 14px;
    padding: 5px 10px;
    white-space: nowrap;
    color: #fff;
    font-weight: 500;
  }

   .moment-bucket{
    background-color: #0077b6;
    line-height: 1.25;
    font-size: 14px;
    padding: 0.45rem 0.63rem;
    white-space: nowrap;
    color: #fff;
    border: none;
    margin-top: 5px;
    margin-left: 14px;
  }
.select-box-value-view{
    font-weight: 400;
    letter-spacing: 0.00938em;
    border: 1px solid #ced4da;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 0px;
    width: 120px;
    padding: 8px;
}
.card-mention{
    width: auto;
    display: inline-block;
    border: 0 solid transparent;
    box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%), 0 2px 6px 0 rgb(206 206 238 / 54%);
    background-color: #fff;
    background-clip: border-box;
    border-radius: 0.25rem;
    padding: 10px;
    margin: 5px 5px;
}
.save-mention-moment{
    padding: 0px 17px 0;
    margin: 20px 0px 5px 5px;
}
.gp-save-moment-member i,
.gp-trash-moment-member i,
.gp-edit-moment-member i,
.save-moment-member i,
.trash-moment-member i,
.edit-moment-member i{
    font-size: 14px !important;
    margin-top: -20px !important;
}

#left-tabs-example-tabpane-manageMoments .spinner-border{
    position: relative;
    top: 40vh;
    left: 50%;
}
.p-sticky{
    position: sticky;
    top: 130px;
    background: #fff;
    margin: 0px -8px;
    z-index: 1;
    padding: 3px 8px;
    box-shadow: rgb(33 35 38 / 10%) 0px 10px 10px -10px;
    background-color: #fff;
}
.p-style .MuiOutlinedInput-root .MuiSelect-select{
    padding-top: 9px !important;
    padding-bottom: 9px !important;
}

.dropdown-val .MuiOutlinedInput-root .MuiSelect-nativeInput{
    opacity: 1 !important;
    background: none;
    border: none;
    top: 0;
    left: 10px !important;
}