.pg-wrap-live{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #ecf0f3;
}

.pg-content-live{
    flex: 1;
}

.popup {
    position: fixed;
    bottom: 40px;
    right: 10px;
    width: 300px;
    padding: 10px;
    background: white;
    border: none;
    background-color: #ecf0f3;
    box-shadow: 12px 12px 16px 0 #b5bbc2, -8px -8px 12px 0 #f8fbff;
    z-index: 1000;
    border-radius: 10px;
  }
  
  #textarea {
    max-height: 250px;
    overflow-y: auto;
    background-color: #ecf0f3;
  }
  
  #textarea ul {
    list-style-type: none;
    padding: 0;
  }
  
  #textarea li {
    margin: 5px 0;
  }

  .live-cut-button{
    width: 42px;
    height: 27px;
    padding-left: 10px;
    display: flex;
    align-items: center;
    color: #1976d2;
    background-color: transparent;
    border: none;
    border-radius: 2px;
  }
  .live-cut-button:hover{
    background-color: #dce9f5;;
  }

  .custom-accordion {
    background-color: #ecf0f3;
    box-shadow: 12px 12px 16px 0 #b5bbc2, -8px -8px 12px 0 #f8fbff !important
  }
  
  .custom-accordion-summary, .custom-accordion-details {
    overflow: hidden;
    background-color: #ecf0f3;
  }

  .live-card{
    background-color: #ecf0f3;
    box-shadow: 12px 12px 16px 0 #b5bbc2, -8px -8px 12px 0 #f8fbff;
  }
  
  .lds-ripple,
.lds-ripple div {
  box-sizing: border-box;
}
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 45px;
  height: 45px;
  color: green; /* Set the color to red */
}
.lds-ripple div {
  position: absolute;
  border: 3px solid currentColor; /* This will now use the red color */
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
.live-wrapper{
  margin-top:0 !important;
  padding:60px 0px 0px 240px;

}
.toggled .live-wrapper{
  margin-top:0 !important;
  padding:60px 0px 0px 50px;
}
.call-details-live{
  display:flex;
  width: '100%';
}

.call-details-live-main-content{
  flex: 5;
   padding: 10px;
}

.call-details-live-secondary-content{
  flex: 2;
  border-left: 2px solid #ccc;
  margin-top: -23px;
}

@keyframes lds-ripple {
  0% {
    top: 21px;
    left: 21px;
    width: 3px;
    height: 3px;
    opacity: 0;
  }
  4.9% {
    top: 21px;
    left: 21px;
    width: 3px;
    height: 3px;
    opacity: 0;
  }
  5% {
    top: 21px;
    left: 21px;
    width: 3px;
    height: 3px;
    opacity: 1;
  }
  100% {
    top: 0;
    left: 0;
    width: 45px;
    height: 45px;
    opacity: 0;
  }
}

/* .scrollable-resizable {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f9f9f9;
  border-top: 1px solid #ccc;
  overflow-y: auto;
  transition: height 0.2s;
} */

.detail-heading{
  margin-bottom: 12px;
  padding: 10px;
}

.divider {
  position: relative;
  height: 7px;
  background: 
  /* #d9d2d2; */
  #e2dfdf;
  cursor: row-resize;
  margin-right: -27px;
}

.grabber {
  position: absolute;
  border-radius: 4px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 7px;
  background: #b3b3b3;
  cursor: row-resize;
}

.handle {
  width: 100%;
  height: 20px;
  background-color: #ccc;
  cursor: ns-resize;
  text-align: center;
  line-height: 20px;
}

.drawer-content {
  padding: 20px;
}

.section {
  margin-left: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.moment-detail{
  padding: 10px;
  /* border-bottom: 1px solid #ccc; */
}

.moment-highlight{
  padding: 7px;
}

.moment-desc{
  font-size: 15px;
  font-weight: bold;
}

.moment-section{
  padding: 10px;
}

.call-notes {
  padding: 11px;
}

.notes-img{
  width: 25px;
  height: 24px;
  margin-right: 8px;
}

.call-notes textarea {
  width: 100%;
  height: 100px;
  margin-top: 10px;
}

.live-assist-scroll{
  overflow: auto;
  height: 2vh;
}
.live-assist-scroll::-webkit-scrollbar{
  width: 8px;
  background-color: #F5F5F5;
}

.live-assist-scroll::-webkit-scrollbar-track{
  box-shadow: inset 0 0 6px rgba(160, 158, 158, 0.3);
}

.live-assist-scroll::-webkit-scrollbar-thumb{
  background-color: rgb(145, 146, 146);
  border-radius: 40px;
}

.exclamation-img{
  width: 20px;
  background-color: #ff4343;
  margin-left: 4px;
  margin-top: -5px;
  border-radius: 16px;
}

#nudges.switcher-wrapper{
  z-index: 0;
  top: 50px;
}
  
/* ************************** Responsive code *************************** */

@media screen and (min-width: 320px) and (max-width: 767px){
  div.live-wrapper{
     padding: 0 !important;
  }
  .call-details-live, .seller{
    flex-direction: column;
  }
  .salesCallCard .user .caller {
    width: 100%;
}
.caller .name span {
  vertical-align: middle;
}
.caller .name{
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.call-details-live-secondary-content{
  margin-top: 0;
}
div.full-search-bar{
  left: 0;
  width: 100%;
}
.bx-menu:before {
  color: #000;
}
.call-details-live-secondary-content .div1.live-assist-scroll{
  height: 420px !important;
}
.switcher-body  .list-group{
  
}

}
@media screen and (min-width: 768px) and (max-width: 991px){
  div.live-wrapper{
     padding: 0 !important;
  }
  .call-details-live, .seller{
    flex-direction: column;
  }
}

audio::-webkit-media-controls-timeline,
audio::-webkit-media-controls-seek-bar {
    pointer-events: none;
    opacity: 0.5;
}

audio::-moz-range-track {
    pointer-events: none;
    /* opacity: 0.5; */
}

audio::-ms-track {
    pointer-events: none;
    /* opacity: 0.5; */
}

