.genral-settings{
    border-right: 1px solid #ced4da;
    margin-right: 20px;
    padding-right: 20px;
}
.logo-input{
}
.logo-avatat {
    width: 110px;
    height: 110px;
    align-items: center;
    background: #0077b6;
    border-radius: 50%;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
    line-height: 45px;
    text-align: center;
    cursor: pointer;
}
.logo-avatat img{
    padding: 0.12rem;
}
.clint-logoInput{
    display: none;
}
.clintLogo-dropdown .dropdown-menu{
    top: -60px !important;
    padding: 0;
}
.clintLogo-dropdown .dropdown-menu li{
    border-bottom: 0.023rem solid #ced4da;
}
.clintLogo-dropdown .dropdown-menu .dropdown-item{
    padding: 0.55rem 1rem;
}
.clintLogo-dropdown .dropdown-item:focus, .clintLogo-dropdown .dropdown-item:hover {
    color: #fff;
    background-color: #0077b6;
}
.custom-file-input::-webkit-file-upload-button {
    display: none;
  }
  .custom-file-input::before {
    content: '+';
    display: inline-block;
    background: linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
  }
  .custom-file-input:hover::before {
    border-color: black;
  }
  .custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  }