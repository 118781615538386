.accordion-body > div a i {
  padding-right: 10px;
}
.side_nav_scroll .accordion .accordion-item {
  border: 0;
}

.side_nav_scroll .accordion .accordion-item .parent-icon button {
  padding: 6px 7px;
  margin: 5px;
}
.side_nav_scroll .accordion .accordion-item .parent-icon button .menu-title {
  font-size: 16px;
  margin-left: 10px;
}
.side_nav_scroll .accordion .accordion-item .parent-icon button i::before {
  font-size: 24.5px;
}
.side_nav_scroll
  .accordion
  .accordion-item
  .parent-icon
  .accordion-button.collapsed::after {
  position: absolute;
  transform: rotate(224deg) translateY(-50%);
  content: "";
  width: 0.5rem;
  height: 0.5rem;
  border-style: solid;
  border-width: 1.2px 0 0 1.2px;
  border-color: initial;
  right: 15px;
  transform-origin: top;
  top: 50%;
  transition: all 0.3s ease-out;
  background-image: none;
}
.side_nav_scroll
  .accordion
  .accordion-item
  .parent-icon
  .accordion-button::after {
  position: absolute;
  transform: rotate(46deg) translateY(-50%);
  content: "";
  width: 0.5rem;
  height: 0.5rem;
  border-style: solid;
  border-width: 1.2px 0 0 1.2px;
  /* border-color: initial; */
  right: 15px;
  transform-origin: top;
  top: 50%;
  transition: all 0.3s ease-out;
  background-image: none;
}
.side_nav_scroll .accordion .accordion-item .accordion-collapse {
  /* border: 1px solid #ededed; */
  margin-left: 2rem;
  border: 1px solid #ddd;
  box-shadow: -7px 5px 27px #ccc;
}
.side_nav_scroll
  .accordion
  .accordion-item
  .accordion-collapse
  .accordion-body
  > div {
  margin-top: 5px;
  margin-bottom: 5px;
  /* padding: 0px 15px; */
}
.side_nav_scroll
  .accordion
  .accordion-item
  .accordion-collapse
  .accordion-body {
  padding: 0px;
}
.side_nav_scroll
  .accordion
  .accordion-item
  .accordion-collapse
  .accordion-body
  > div
  i::before {
  font-size: 14.5px;
}
.side_nav_scroll
  .accordion
  .accordion-item
  .accordion-collapse
  .accordion-body
  > div
  a {
  width: 100%;
  padding: 4px 15px;
}

.side_nav_scroll
  .accordion
  .accordion-item
  .accordion-collapse
  .accordion-body
  > div:hover
  a {
  color: #fff !important;
  text-decoration: none !important;
  background: #082545 !important;
}
.side_nav_scroll
  .accordion
  .accordion-item
  .accordion-collapse
  .accordion-body
  div:nth-child(2)
  a {
  padding: 5px 15px;
}
.side_nav_scroll .accordion .accordion-item h2 button:focus {
  box-shadow: none;
}
.custome-nav .nav-item a {
  border: 1px solid transparent;
}

.custome-nav .nav-item a:hover {
  border: 1px solid;
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate;
}
.lni-bulb {
  height: 29.39px;
  padding-top: 2.5px;
}
.hide_icon button {
  margin-top: 0px !important;
}
.wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper .hide_icon button {
  padding: 6px 0px 8px 10px !important;
}
.wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper .hide_icon button a i {
  margin-right: 0 !important;
}
.wrapper.toggled:not(.sidebar-hovered) .sidebar-custom-collapse.accordion-item .accordion-collapse {
  display: none;
}
.wrapper.toggled:not(.sidebar-hovered) .sidebar-custom-collapse.accordion-item .customer-collapse .accordion-collapse {
  display: block;
}
.hide_icon button a {
  padding-bottom: 5px !important;
  padding-top: 5px !important;
  padding-left: 15px !important;
  margin-left: -15px;
  margin-right: -20px;
  width: 115% !important;
}
/* .hide_icon button a:hover,.hide_icon button a:active, .hide_icon button a:focus{
    background-color: #fff !important;
  } */
.hide_icon button::after {
  display: none;
}
.accordion-body > div a i::before {
  margin-right: 0px;
}
.hide_icon button a:hover,
.hide_icon button a:active,
.hide_icon button a:focus {
  color: #fff !important;
  text-decoration: none !important;
  background: #082545 !important;
}

.side_nav_scroll .accordion .accordion-item .parent-icon button .active {
  background-color: #082545;
  color: #fff !important;
}

.custom-icon-color {
  color: gray; /* Change this to your desired color */
}

.side_nav_scroll .accordion .accordion-item:not(.mm-active) .parent-icon button {
  background-color: transparent;
}

#menu{
  flex-wrap:wrap;
}

.sidebar-wrapper .metismenu {
  overflow-x: hidden;
  box-sizing: border-box;
}