.update-btn {
    /* float: right;  */
    background-color: #0077b6;
    outline: #0077b6;
    border-color: #0077b6;
    color: white;
  }
.cancel-btn {
    background-color: #e7eaf3;
    margin-right: 5px;
}
.cancel-btn:hover {
    background-color: #e7eaf3;
    margin-right: 5px;
}
.update-btn:hover {
    color: #fff;
    background-color: #0077b6;
    border-color: #0077b6;
}
.form-check-input:focus {
    border-color: rgba(0,0,0,.25);
    outline: 0;
    box-shadow: none;
}
.form-check-input:checked {
    background-color: #0077b6;
    border-color: #0077b6;
}
#left-tabs-example-tabpane-manageUsers .spinner-border{
    position: relative;
    top: 40vh;
    left: 50%;
}
.view-team-card-details .card.edit-border .card-body form div:nth-child(9) .input-group>div{
    width: 100%;
}
.view-team-card-details .card.edit-border .card-body form div:nth-child(9)>div input{
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}
#inputManager .react-autosuggest__input{
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.9;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
#inputManager .react-autosuggest__container{
    width: 100%;
}

.add-alias-btn{
    margin-top: 30px;
}