.topic-select{
    position: relative;
    margin-top: 15px;
    margin-left: 200px;
    height: 40px;
    width: 500px;
  }
  
  .topic-questions{
    margin-top: 20px;
  }
  
  .question{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    /* margin-top: 5px; */
    margin : 5px;
    /* margin-bottom: 5px; */
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05), 0px -2px 3px rgba(0, 0, 0, 0.05);
  }

  .question p{
    margin-bottom: 8px;
    padding-top: 10px;
  }

  .topic-question p{
    margin: 0px;
    padding-top: 8px;
    padding-bottom : 8px
  }
  
  .question-buttons{
    display: flex;
    align-items: center;
    gap: 10px
  }
  
  .question-button{
    height: 20px;
    width: 40px;
  }

  .status{
    display: flex;
    justify-content: space-between;
    gap: 150px;
  }

  @media (max-width: 768px) {
    .topic-select {
      margin-left: 0;
      width: 100%;
    }
  
    .question {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  
  @media (max-width: 480px) {
    .question {
      padding-left: 5px;
      padding-right: 5px;
    }
  
    .status {
      gap: 10px;
    }
  }