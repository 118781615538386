.custom-range-select{
    max-width: 100%;
}
.icon-picker {
    margin: 0 10px;
}
.icon-picker button {
    margin-top: 0;
}
.icon-picker button.btn-default, .icon-picker button.btn-default.open, .icon-picker button.btn-default:hover {
    background-color: #fff;
}
.icon-picker hr {
    margin: 5px 0px;
}
.icon-picker .outline-none {
    outline: 0;
}
.icon-picker .outline-none:focus {
    outline: 0;
}
.icon-picker.open > button {
    margin-top: 0;
}
.icon-picker.open > button.btn-default, .icon-picker.open > button.btn-default.open, .icon-picker.open > button.btn-default:hover, .icon-picker.open > button.btn-default:focus {
    background-color: #fff;
}
.icon-picker .icon-dropdown-text {
    width: 40px;
    display: inline-block;
}
.icon-picker .dropdown-menu {
    width: 262px;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 1px;
}
.icon-picker .dropdown-menu .header {
    display: block;
    float: none;
    width: 100%;
    text-align: left;
    padding: 0;
    height: auto;
    font-size: 12px;
    color: #888;
    padding: 5px 10px 0 10px;
}
.icon-picker .dropdown-menu .icons {
    list-style: none;
    padding: 0;
}
.icon-picker .dropdown-menu .icons:after {
    clear: both;
    content: '';
    display: table;
}
.icon-picker .dropdown-menu .icons li {
    float: left;
    text-align: center;
    padding: 5px;
    position: relative;
    width: 40px;
    height: 40px;
    margin: 5px 5px;
}
.icon-picker .dropdown-menu .icons li .icon-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0;
}
.icon-picker .dropdown-menu .icons li .icon-wrapper:hover, .icon-picker .dropdown-menu .icons li .icon-wrapper.selected {
    border: 2px solid black;
}
.icon-picker .dropdown-menu .icons li .icon-wrapper .icon {
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    margin: 0;
}
.icon-picker .dropdown-menu .icons li .icon-wrapper .icon [class^="fa-"], .icon-picker .dropdown-menu .icons li .icon-wrapper .icon [class*=" fa-"] {
    font-size: 30px;
    color: #666;
    margin: 0;
}
.icon-picker .dropdown-menu .icons li .color {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 90%;
    width: 90%;
    display: block;
}
.icon-picker .dropdown-menu .icons li .color.border-radius {
    border-radius: 50%;
}
.icon-picker .dropdown-menu .icons li .color:hover, .icon-picker .dropdown-menu .icons li .color.selected {
    border: 2px solid black;
}
.icon-picker .dropdown-menu .icons li .color:hover:before, .icon-picker .dropdown-menu .icons li .color.selected:before {
    content: " ";
    border-radius: 50%;
    position: absolute;
    z-index: 100;
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    border: 2px solid #fff;
}
[class^="fa-"].h1, [class*=" fa-"].h1 {
    font-size: 20pt;
}
button .caret {
    margin-left: 12px !important;
}

.colors>div>div>input{
    width: 100% !important;
}
.w-30{
    width: 30%;
}
.w-20{
    width: 20%;
}
.w-18{
    width: 18%;
}
.w-5{
    width: 5%;
}
.report-datepicker{
    line-height: 2.3 !important;
    border-radius: 0 !important;
    width: 100%;
}
.includeEmp{
    color: rgba(0, 0, 0, 0.38);
}
.marginM-0>div>div>div>div .MuiAutocomplete-popper{
    position: absolute;
    top: 0;
}
.rating-font .MuiAutocomplete-root .MuiFormControl-root .MuiOutlinedInput-root input{
    font-size: 14px;
    color: #212529;
    padding: 3px 0 3px 10px !important;
}
.del-rating i{
    margin: -10px 0 0 10px;
}
.creat-rating i{
    margin: -10px 0 0 10px;
}