.know-page-wrapper{
        height: 100%;
        margin-top: 0px;
        margin-bottom: 30px;
        margin-left: 263px;
}
.maindiv{
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    height: 83vh;
    padding-top: 5rem;
    display: flex;
    justify-content: space-around;
    overflow-y: auto;
}
.know-div{
    width: 82%;
}
.know-intro{
    line-height: 45px;
    font-size: 40px;
}
.intro-text{
    background: linear-gradient(to right, #688AE9, #C66D7B);
    -webkit-background-clip: text;
    background-clip:text;
    color:transparent;
    display:inline-block;
}
.know-default-prompt-container{
    display: flex;
    gap: 10px;
    margin-bottom: 1rem;
}
.know-default-prompt{
    display: flex;
    justify-content: space-around;
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    height: 6rem;
    width: 8rem;
    padding-top: 7px;
    background-color: #f0f0f0;
    cursor: pointer;
}
.know-default-prompt b{
    font-size: medium;
}
.know-dp-icon{
    position: relative;
    bottom: -70%;
}
.know-chat p{
    margin-bottom: 4px;
    margin-left: 1.7rem;
}
.know-chat-opt{
    display: flex;
    gap: 10px;
    margin-bottom: 4px;
    margin-left: 1.7rem;
    margin-top: 10px;
}
.know-chat-opt button{
    background-color: #f0f0f0;
    border: none;
    border-radius: 20px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 5px;
    padding-bottom: 5px;
}
.know-chat-opt-keyword{
    margin-bottom: 4px;
    margin-left: 1.7rem;
    margin-top: 10px;
    display: inline-block;
}
.know-chat-opt-keyword-button{
    background-color: #f0f0f0;
    border: none;
    border-radius: 20px;
    margin-bottom: 10px;
    margin-right: 10px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 5px;
    padding-bottom: 5px;
}
.know-chat-icon{
    display: flex;
    gap: 5px;
    height: 2.3rem;
}
.know-chat-icon i{
    font-size: x-large;
}
.know-chat-icon b{
    padding-top: 7px;
}
.know-input-div{
    position: fixed;
    bottom: 50px;
    width: 61%;
}
.know-input-div input{
    width: 100%;
    border: 1px solid #f0f0f0;
    border-radius: 10px;
    height: 50px;
    background-color: #f0f0f0;
    color: black;
    outline: none;
    font-size: 16px;
    padding-left: 12px;
}
.know-submit-button{
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -3rem;
    border: none;
}
.know-submit-button i{
    font-size: x-large;
    color: #525f7f;
}
.know-revise-button{
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -6rem;
    border: none;
}
.know-revise-button i{
    font-size: x-large;
    color: #525f7f;
}

.know-chat {
    opacity: 0;
    animation: fadeIn 0.5s ease forwards;
  }
  
  .typing-dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: #525f7f;
    border-radius: 50%;
    animation: blink-dot 1s infinite step-end;
    margin-left: 10px; /* Adjust as needed for spacing */
    vertical-align: middle;
  }
  
  @keyframes blink-dot {
    50% {
      opacity: 0;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

.custom-scrollbar::-webkit-scrollbar {
    width: 8px;
    background-color: #f0f0f0;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}
