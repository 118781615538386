    .odio-header {
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    padding: 0 15px;
    background: #ffffff;
    background-clip: border-box;
    border-bottom: 1px solid #e4e4e4;
  }
.logo-icon-page {
    width: 36px;
}
.odio-logo-text {
    font-size: 22px;
    margin-left: 5px;
    margin-bottom: 0;
    letter-spacing: 1px;
    color: #082545;
}
.odio-logo-text .logo-imgg {
    font-size: 22px;
    margin-left: 10px;
    margin-bottom: 0;
    letter-spacing: 1px;
    color: #082545;
    width: 100px;
}

.page-header{
    display: flex;
    text-align: left;
    margin-top: 40px;
    margin-left: 26px;
}
#upload.page-header{
    margin-left: 289px;
    margin-top: 40px;
}

.call-upload{
    display: flex;
    justify-content: center;
    margin-left: 25px;
    margin-right: 25px;
    box-shadow: 0px 4px 16px #0000001A;
    border-radius: 8px;
    opacity: 1;
    background: #ffffff 0% 0% no-repeat padding-box;
}
#upload-glid.call-upload{
    margin-left: 287px;
}
#upload-rec.call-upload{
    margin-left: 287px;
    display:flex;
    height:588px;
}

.bg-image{
    background-image: url('Group 26.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin-top: 18px;
    margin-bottom: 18px;
    /* padding: 60px; */
    height: 507px;
}

.table-heading{
    text-align: center;
    padding: 54px;
    color: #222222;
}

.call-upload table{
    width: 166px;
    height: 159px;
    margin-top: 10px;
    border-color: #222222;
}
.center-table{
    margin-left: auto;
    margin-right: auto;
}
.table-heading table tr{
    border-color: #222222;
}
.table-heading table th{
    border-color: #222222;
}
.table-heading table td{
    border-color: #222222;
}
.center-table thead{
    border-color: #707070;
    text-align: center;
}
.center-table thead td{
    border-color: #222222;
}
.center-table thead tr th{
    color: #222222;
}
.center-table tbody td{
    color: #222222;
}

.bg-image .file-upload-content{
    width: 706px;
    height: 160px;
}

.bg-image .file-upload-content fieldset{
    height: 163px;
    margin-top: 10px;
}
.bg-image .rec-submit fieldset{
    height: 131px;
    margin-top: 55px;
}
.rec-submit #fileselect{
    /* height: 61px;
    top: 213px; */
    height: 70px;
    top: -220px;
}
.bg-image .uploaded-file{
    top: -209px;
    padding: 10px;
    font-size: 14px;
}

.bg-image .rec-submit .uploaded-file{
    top: -179px;
    padding: 10px;
    font-size: 14px;
}

.page-footer-odio{
    display: flex;
    justify-content: center;
    padding: 0.75rem;
}
#uploadGLID.page-footer-odio{
    margin-left: 285px;
}
.page-footer-odio>* {
    margin: 0.25rem;
}

.page-footer-odio .btn {
    background-color: #0077b6;
    color: white;
    width: 75px;
    font-size: 14px;
    margin-bottom: 30px;
}

.upload-call-rec{
    position: absolute;
    display: flex;
}
.first-div{
    position: absolute;
    top: 254px;
    /* left: 312px  */
    /* margin-left: 377px; */
    left: 289px;
}
.upload-call-rec .error-message{
    top: 293px;
    position: relative;
    left: 262px;
}
.inputWithButton .error-message{
    position: relative;
    left: 538px;
}

.upload-call-rec .btn.btn-primary{
    background-color: #0077b6;
    color: white;
    width: 56px;
    font-size: 11px
}

.inputWithButton {
    position: relative;
    height: 100px;
    width : 200px;
}
.inputWithButton input{
    width:720px;
    border-radius:10px;
    margin:3px;
    padding:11px;
    border:1px solid #0057b6
}
.inputWithButton button {
    position: absolute;
    right: -511px;
    top : 13px;
    border: none;
    background: #ffffff;
    font-size: 19px;
} 
  

.seller-agent-trans{
    margin-top: 91px;
}
.seller-agent-trans h3{
    margin-left: 272px;
}
.seller-agent-trans audio{
    margin-left: 267px;
    margin-top: 29px;
    width: 86%;
}

.message-container-trans {
    padding-left: 300px;
    padding-right: 31px;
    margin-top: 50px;
}

.message-box {
    display: inline-block;
    width: 100%;
}
.message-box button{
    border-radius: 14px;
}

.button.play{
    border: 0;
    background-color: transparent;
    font-size: 18px;
}

.button.pause {
    border: 0;
    background-color: transparent;
    font-size: 18px;
}

.redirect-trans{
    background-color: #E7DBD6;
    font-variant: normal;
    display: inline-block;
    padding: 1%;
    border-radius: 5px;
    margin: 7px 0px !important;
    min-width: 100px;
    max-width: 60%;
    position: relative;
}

.separated-class{
    float: right;
    background-color: #D6DDE7;
}

.inputWithButton .spinner-border{
    width: 1.2rem;
    height: 1.2rem;
    border-width: 0.17em;
}