.col-6.text-sm-end.cancelUpadte-btn {
    margin-top: 45px;
    text-align: right;
}

.cancelUpadte-btn button:hover{
    background-color: #e7eaf3;
}
.cancelUpadte-btn button{
    /* width: 156px; */
    background-color: #e7eaf3;
}
.cancelUpadte-btn button i{
    color: #000;
}
#left-tabs-example-tabpane-manageCoe .spinner-border{
    position: relative;
    top: 40vh;
    left: 50%;
}
.backtomanageCoe-table{
    color: #000;
    font-size: 20px;
    width: 25px;
    height: 25px;
    line-height: 16px;
}
.add-coe-btn-top{
    position: relative;
    bottom: -40px;
    right: 165px;
}
.addUserInCoe .react-autosuggest__input{
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}