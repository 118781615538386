.caller-badge {
    height: 18px;
    font-size: 10px;
    pointer-events: none;
    padding: 4.5px 7px;
    border-radius: 3px;
}
.caller-badge i {
    font-size: 12px !important;
    margin-top: -1px;
}
.meet-ing-img{
    width: 65px;
    height: 65px;
    border-radius: 50%;
    border: 0px solid #e5e5e5;
    padding: 12px;
}
.account-content-text{
    -webkit-user-select: none; 
    -ms-user-select: none;
    user-select: none;
}
.account-content .card.edit-border .card-body.account-page {
    padding: 0rem 2rem 1rem !important;
}
.account-content .card.edit-border .card-body.account-page .nav.nav-tabs {
    padding-top: 1rem !important;
}
.account-content button.update-btn {
    /* float: right; */
    background-color: #0077b6;
    outline: #0077b6;
    border-color: #0077b6;
    border: none;
    color: white;
}
.table-striped>tbody>tr:nth-of-type(odd), .table-striped>tbody>tr:nth-of-type(even) {
    --bs-table-accent-bg: #fff;
}

/* #innerinboundTable thead{
    background: #0077B6;
    color: #fff;
}
#innerinboundTable thead:hover{
    background: #0077B6 !important;
    color: #fff;
} */
/* table tr:hover {
    background-color: #0077B6;
} */
/* .table>:not(caption)>*>* {
    padding: 0.4rem 0.5rem;
} */

.modalcard-body .profile-letter h3 {
    color: #fff;
    font-weight: normal;
}
.modalcard-body .profile-letter {
    width: 100px;
    height: 100px;
    border: 10px solid #0077B6;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 5px #0077b6;
    background: #0077B6;
    color: #fff !important;
}

#manager-close-button, .cancel-btn {
    background-color: #e7eaf3;
    margin-right: 5px;
    width: 140px;
    color: #000;
    border: none;
    line-height: 1.5;
    padding: 6px !important;
}

/* Ravinder Css */

#changepswrd{
    padding: 6px;
    width: 152px;
}

#left-tabs-example-tabpane-myTeam .spinner-border{
    position: relative;
    top: 30vh;
    left: 50%;
}
#changepswrd .spinner-border {
    width: 1.2rem;
    height: 1.2rem;
    border-width: 0.17em;
}

.account-page .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #0077B6;
    background-color: #fff;
    border-color: transparent;
    border-bottom: 2px solid;
}

.account-page .nav-link:focus, .nav-link:hover {
    color: #0077B6;
}
.account-page .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    border-color: #fff #fff #0077B6 #fff;
    isolation: isolate;
}
.account-page .nav-link {
    display: block;
    padding: 0.5rem 1rem;
    color: #585858;
    text-decoration: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
