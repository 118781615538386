section.first-section p{
    color: #9e9e9e;
}
section.first-section .btn-group{
    border: 1px solid #cccccc4d;
    color: #9e9e9e;
}
section.first-section .btn-group .btn{
    color: #9e9e9e;
}
.second-section img{
    width: 100px;
}
.create-btn{
    border: 1px solid;
}
.create-button{
    margin-right: 5px;
}
.second-section .img_section{
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 20px;
    width: 100%;
    height: 100px;
    background-color: #fff;
    border: 1px solid #cccccc4d;
    border-radius: 4px;
}
.dashStats{
    border-radius: 10px !important;
}
.dashStats:hover{
    box-shadow: #63636333 0px 2px 8px 0px;;
}
.second-section .img_section h2{
    color: #7967ef;
}
.second-section .img_section p{
    font-size: 12px;
    margin: 0;
    color: #9e9e9e;
}
.second-section .img_section .text_sign p img{
    width: 16px;
    margin-left: 8px;
    margin-bottom: 4px;
}
.parent{
    display: flex;
    gap: 20px;
    /* cursor: pointer; */
}
.img-ranking{
    width: 20px;
}
.img-graph, .img-progress{
    width: 150px;
}

.rank{
    vertical-align: middle;
}

.modal-create-session:hover{
    box-shadow: #c9aaf8 0px 2px 8px 0px;
}

.modal-upload-session:hover{
    box-shadow: #76cd7b 0px 2px 8px 0px;
}

.create-session-short-title{
    font-size:14px !important;
    font-weight:500 !important;
}

.coaching-input-container{
    display:flex;
    border-radius:5px;
}

.stats-coaching-dash{
    width: 98%;
    margin: 1em;
}

#scrollableDiv {
    max-height: 400px; 
    overflow-y: auto; 
    overflow-x: hidden;
    position: relative; 
  }

.coaching-session-list-card{
    border-radius: 10px;
    border-left: 5px solid #1976d2;
}
.coaching-session-list-card-active{
    border-radius: 10px;
    border: 1px solid #1976d2;
    border-left: 5px solid #1976d2;
}

/* <-------------------> */
.form-parent{
    border-radius: 5px;
    border: 2px dashed #ababab;
    padding: 20px;
}
.parent-p,.form-parent,.btn-parent{
    text-align: center;
}
.coaching-input-container form p span{
    font-weight: bold;
    color: #6990F2;
}
.file-uploadd{
 color: #ababab;
}
.file-format{
    color: #ababab;
    font-size: 10px;
    text-align: center;
    margin: 0;
    font-weight: bold;
}
.file-format.ss{
    margin: 0;
}
.form-parent .sub-head{
    margin: 0;
}
.btn-parent Button{
    padding: 13px 40px;
    font-size: 16px;
}

.session-file-icon{
    width: 40px;
    margin-right: 8px;
    border-radius:7px;
}

.due-session{
    color: #9e9e9e;
    font-size:smaller !important;
}
.coaching-percentage-info{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.coaching-icon-info{
    display: flex;
    align-items: center;
}
.coaching-icon-info p{
    font-size: 10px;
    margin: 0;
}
.coaching-icon-info .callCount .box{
    padding: 2px;
}
.coaching-icon-info .callCount .box:nth-child(1) {
    border: 1px solid #e62e2e;
    color: #e62e2e;
}
.div-coaching{
    position: absolute;
    right: 4rem;
    padding-right: 0;
}
div.div-coaching.scoreCommonStyle.callScore span{
    font-size: 10px;    
}
.upper-coaching-form .MuiFormControl-root .MuiOutlinedInput-root{
    padding: 4px 0px !important;
    border-radius: 7px;
}
.hover-box:hover .one{
  display: block;
}
.one{
    display: none;
    position: absolute; 
    left: 64%;
    top: 4%;
    z-index: 10;
    background-color: #fff;
    box-shadow: -5px 2px 3px #ddd;
    border: 1px solid #ddd;

}
.one .header-info h5{
    background: #082545;
    color: #fff;
    padding: 10px;
}
.one .header-info ul{
    padding: 1.5rem;
}
.one .header-info li{
    margin-bottom: 0.4rem;
    color: #000;
}
.coaching_wrap_box{
    display: flex;
}
.card-body .handling-body{
    display: flex;
    justify-content: space-between;
}

/* ---------------------- Responsive-coaching-part-------------------------- */

@media screen and (min-width: 320px) and (max-width: 767px){
    .second-section .parent{
        display: block;
    }
    section.second-section .img_section{
        width: 100%;
        margin-bottom: 10px;
    }
    .coach-responsive-block.col-6{
        width: 100%;
    }
    div.coaching_wrap_box.team{
        display: block;
    }
    .content_summary{
       width: 200px;
    }
    div.div-coaching{
        right: 2.5rem;
    }
    div.coaching_wrap_box.card-body.team{
        height: auto !important;
    }
    .coaching-session-list-card-active .card-body {
        padding: 1rem 0.5rem;
    }
    .question-block .options{
        flex-direction: column;
        align-items: flex-start;
    }
    div.coaching-percentage-info{
        align-items: flex-start;
        flex-direction: column;
    }
    div p.h5{
        font-size: 12px;
    }
    .card-body .handling-body{
        flex-direction: column;
    }
}
@media screen and (min-width: 768px) and (max-width: 1024px){
    .second-section .parent{
        display: grid;
        grid-template-columns: auto auto;
    }
    section.second-section .img_section{
        width: 100%;
        margin-bottom: 10px;
    }
    .coach-responsive-block.col-6{
        width: 100%;
    }
    div.coaching_wrap_box.team{
        display: block;
    }
    .content_summary{
       width: 200px;
    }
    div.div-coaching{
        right: 2.5rem;
    }
    div.coaching_wrap_box.card-body.team{
        height: auto !important;
    }
    .coaching-session-list-card-active .card-body {
        padding: 1rem 0.5rem;
    }
    .question-block .options{
        flex-direction: column;
        align-items: flex-start;
    }
}

