.gjs-dashed{
    padding: 10px !important;
}
.grapesJS_button{
    display: flex;
    justify-content: center;
}
.check-button{
    display: flex;
    justify-content: center;
    margin-top: 16px;
}
.check-button label{
    font-size: 16px;
}
.check-button input{
    margin: 3px 8px 0 0;
}
.gjs-pn-devices-c  {
    padding: 0px !important;
}
.gjs-devices-c{
    padding: 1px 1px !important;
}
.animated-text-grape{
    display: inline-block;
    background: #fff;
    padding: 10px;
    border-radius: 15px;
    position: relative;
    animation-name: example;
    animation-duration: 3s;
    color: red;
    margin-bottom: 20px;
}
.parent-animated {
    transition: opacity 0.5s ease-out;
    opacity: 1;
  }
  
  .parent-animated.fade-out {
    opacity: 0;
    pointer-events: none; 
  }
@keyframes example {
    0%   {top:-50px;}
    50%  {top:0px;}
    }