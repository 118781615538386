.file-url-btns{
    background: rgba(244,244,245);
    border-radius: 5px;
    border: 1px solid #aba9a9;
}
.file-url-btns p{
    color: #000;
    background: none;
    border: none;
    padding: 10px;
    border-radius: 5px;
}
.file-url-btns .active{
    color: #000;
    background: #fff;
    box-shadow: 0px 0px 0px 1px #aba9a9;
    padding: 10px;
}
.file-upload-content{
    height: 200px;
}
.file-upload-content #fileselect{
    position: relative;
    top: -163px;
    width: 100%;
    height: 163px;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding: 135px 0px 0;
    border-width: 1px;
    border-radius: 5px;
    border-style: dashed;
    border: none;
    background-color: transparent;
    color: rgb(107, 119, 140);
    outline: none;
    transition: border 0.24s ease-in-out 0s;
    cursor: pointer;
    opacity: 0;
    z-index: 1;
}
.file-upload-content label{
    width: 100%;
    flex: 1 1 0%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding: 60px 20px;
    border-width: 1px;
    border-radius: 5px;
    border-color: rgb(36, 84, 255);
    border-style: dashed;
    background-color: rgb(230, 241, 255);
    color: rgb(107, 119, 140);
    outline: none;
    transition: border 0.24s ease-in-out 0s;
    cursor: pointer;
}

#filedrag
{
	display: none;
	font-weight: bold;
	text-align: center;
	padding: 1em 0;
	margin: 1em 0;
	color: #555;
	border: 2px dashed #555;
	border-radius: 7px;
	cursor: default;
}

#filedrag.hover
{
	color: #f00;
	border-color: #f00;
	border-style: solid;
	box-shadow: inset 0 3px 4px #888;
}
.uploaded-file{
    border: 1px solid #aba9a9;
    position: relative;
    top: -150px;
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
}
.uploaded-file b{
    color: #000;
    font-weight: 400;
}
.modalFooter{
    z-index: 999;
}
.call-upload-modal{
    overflow-y: scroll;
}

.buttonWrap .buttons{
    color: #fff;
    background-color: #0077b6 !important;
    border-color:#0077b6 !important;
    width: 48% !important;
    margin-right:5px
}

/* .btnWrap .btn-primary:hover{
    color: #fff;
    background-color: #0077b6;
    border-color:#0077b6;
} */

/* .bar-color{
    background-color: #0d6efd!important;
} */

.t-tip{
    position: relative;
    top: -62px;
    left: 25px;
    z-index: 0;
}

 .moments{
    position: absolute;
    left: -30px; 
    top:-4;
    border-radius: 4px;
    color: #000;
    padding: 0 4px 3px;
    width: fit-content;
    white-space: nowrap;
    line-height: 1.2;
    padding: 2px 4px 2px 4px;
    opacity: 1;
    cursor: pointer;
 }

 .moments:hover, .moments:focus{
    box-shadow: 0 5px 5px 0 #f2f765,0 5px 30px 0 #f2f765;
    transform: translateY(-0.25em);
 }

.drop-tooltip {
    position: absolute;
    top: 40px;
    left: -27px;
    width: 4px;
    border-radius: 0% 50% 50% 50%;
    border: 1px solid #00000029;
    background-color: #00000014;
    transform: rotate(222deg);
    padding: 1px 11px 0px 2px;

  }

.drop-text{
    display: block;
    transform: rotate(139deg);
    color: #000;
    width: 100%;
    padding: 0px 0px;
    position: relative;
    top: -3px;
    right: -6px;
    font-size: 9px;
} 

.callListingExcel{
    display: flex;
    justify-content: space-between;
}