/* <------------------------New-footer-css-----------------------------> */

.newfooter img{
    width: 30%;
  }
  .new-footer-dark{
      background-color: #2b2b2b;
      border-top-width: 1px;
      border-right-width: 0px;
      border-bottom-width: 1px;
      border-left-width: 0px;
      padding: 20px;
  }
  .new-footer-dark .social-icon{
    text-align: center;
  }
  .new-ul-footer ul { 
     list-style-type: none;
     margin-top: 1rem;
     text-align: center;
  }
  .new-ul-footer ul li{ 
    border-left: 1px solid #ffffff54;
    display: inline-block;
    padding: 8px;
  }
  .new-ul-footer ul li:first-child{
    border-left: 1px solid transparent;
  }
  
  .new-ul-footer ul li a{ 
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
  }
  .new-footer-dark .social-icon i{
      color: #ffffff;
      background-color: #5367a0;
      border-radius: 50%;
      padding: 0px;
      font-size: 34px;
      width: 50px;
      height: 50px;
      margin-left: 20px;
      cursor: pointer;
  }
  .new-footer-para {
    line-height: 30px;
  }
  .new-border-partition{
    border: 2px solid #5367a0;
    margin-bottom: 20px;
  }
  .footer-section-left{
    text-align: left !important;
  }
  .page-new-footers{
    margin-top: 2.5em;
  }
.new-ul-footer ul>li {
    display: inline-block;
}
  
  @media screen and (min-width: 320px) and (max-width: 767px) {
    footer.page-new-footers{
      background: #ffffff;
      position: absolute;
      text-align: center;
      padding: 7px;
      font-size: 14px;
      border-top: 1px solid #e4e4e4;
      margin-left: 0;

    }
    .page-new-footers .footer-section-left{
      text-align: center !important ;
    }
    .page-new-footers .MuiBox-root div{
      flex-direction: column;
      align-items: center;
      display: flex;
      width: 100vw;
    }

    .page-new-footers .new-footer-dark{
      width: 100%;
    }

    .page-new-footers .new-footer-dark .new-ul-footer ul{
      flex-direction: column;
      padding: 0;
    }

    .page-new-footers .new-footer-dark .new-ul-footer ul li{
      border-bottom:1px solid #ffffff54 ;
      border-left: none;
      display: block;
      padding: 15px;
    }
    .page-new-footers .new-footer-dark .new-ul-footer ul li:last-child{
      border-bottom: none;
    }

  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    footer.page-new-footers{
        margin-left: 0;
  
      }
  }